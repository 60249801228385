import { StyledAvatar } from './Avatar.styled';

export type AvatarProps = {
  avatarImg?: string;
  avatarAlt: string;
  className?: string;
  sizes?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Avatar: React.FC<AvatarProps> = ({
  avatarImg,
  avatarAlt,
  className,
  sizes,
  ...rest
}) => {
  return (
    <>
      {avatarImg && (
        <StyledAvatar className={className} sizes={sizes}>
          <img
            {...rest}
            width={40}
            height={40}
            src={decodeURI(avatarImg)}
            alt={avatarAlt}
          />
        </StyledAvatar>
      )}
    </>
  );
};

export default Avatar;
